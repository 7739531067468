
import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import variables from "@/router/api";

export default defineComponent({
  name: "dashboard-claim-summary",
  components: {},
  props: {
    claimsSummary: Object
  },
  data() {
    return {
      totalValue: 0.0 as number
    };
  },
  setup() {
    onMounted(() => {
      MenuComponent.reinitialization();
    });
  },
  created() {
    setTimeout(() => {
      this.totalValue = this.claimsSummary as any;
    }, 1500);
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);

      // if (isNaN(amount)) {
      //   return variables.addCommaToNumberString(amount);
      // } else {
      //   return 0.0;
      // }
    }
  }
});
