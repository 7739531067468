
import { defineComponent, onMounted } from "vue";
import ClaimHistoryTable from "@/components/individual/ClaimHistoryTable.vue";
import DashboardClaimSummary from "@/components/individual/DashboardClaimSummary.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "claim-history",
  components: {
    ClaimHistoryTable,
    DashboardClaimSummary,
  },
  data() {
    return {
      claimsSummary: {},
      claims: [],
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Claims History", ["Claims"]);
    });
  },
  created() {
    // Get client filed claims
    this.getClaims();

    //Set page title
    document.title = "Claims History | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getClaims() {
      //Fetch client filed claims
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.CLAIMS_ROUTE)
          .then(({ data }) => {
            //Assign data to props
            this.claimsSummary = data.data.claimsSummary;
            this.claims = data.data.claims;
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
  },
});
